<template>
  <common :title="title">
    <div class="phone">
      <div class="submit-t">
        <div class="phone-number">
          <div class="label">手机号：</div>
          <input
            type="number"
            v-model="aoiUserInfoForm.mobileNum"
            placeholder="请输入手机号"
          />
          <div class="btn" v-if="messageCode" @click="sendCodes">
            发送验证码
          </div>
          <div class="btn" v-else>{{ countdown }}秒后重新获取</div>
        </div>
        <div class="code">
          <div class="label">验证码：</div>
          <input
            type="number"
            @focus="focusEvent($event)"
            @blur="blurEvent"
            v-model="aoiUserInfoForm.verificationCode"
            placeholder="请输入验证码"
          />
          <div class="btn placeholder"></div>
        </div>
        <div class="roomNumber" v-if="isRegister == 0">
          <div class="label">房号：</div>
          <div class="value">
            <div class="room" @click="isShowCas = true">
              {{ houseName }}
            </div>
          </div>
          <div class="btn placeholder"></div>
        </div>
      </div>
      <div class="submit-b" id="loginBtn" @click="confirmSure">确认</div>
      <!-- 占位元素 -->
      <div id="placeholder-btn"></div>
    </div>

    <div class="popup">
      <van-popup v-model="isShowCas" round position="bottom">
        <van-cascader
          v-model="aoiUserInfoForm.spaceId"
          :field-names="fieldNames"
          title="请选择所在房号"
          active-color="#1989fa"
          @change="change"
          :options="areaItemList"
          @close="isShowCas = false"
          @finish="onFinish"
        />
      </van-popup>
    </div>
  </common>
</template>

<script>
import Vue from "vue";
import { Cascader, Popup } from "vant";
Vue.use(Cascader);
Vue.use(Popup);
import storage from "../utils/storage";
import common from "../components/common.vue";
import { areaLinkUrl, aoiRegister, sendCode, aoiLogin } from "./api.js";
export default {
  name: "mobilePhoneRegister",
  components: {
    common,
  },
  props: {},
  data() {
    return {
      messageCode: true,
      countdown: 60,
      title: "手机号注册登录",
      isRegister: 0, //是否注册 0 为注册 1为登录
      houseName: "",
      isShowCas: false, //控制选择房号弹窗
      areaItemList: [], //社区房号信息
      fieldNames: {
        text: "name",
        value: "id",
        children: "children",
      },
      aoiUserInfoForm: {
        deviceNo: storage.get('deviceNo') || '',
        mobileNum: "",
        registerType: 3, //注册方式 1身份证 2人脸 3手机号
        spaceId: "",
        verificationCode: "", //验证吗
      },
      hasChildren: true,
    };
  },
  watch: {},
  created() {
    if (this.isRegister == 0) {
      this.getRoomList();
    }
    const { isRegister } = this.$route.query;
    if (![null, undefined, ""].includes(isRegister)) {
      this.isRegister = isRegister;
      this.title = isRegister == 0 ? "手机号注册登录" : "手机号登录";
    }
  },
  mounted() {},
  methods: {
    change(data) {
      if (data.selectedOptions && data.selectedOptions[data.tabIndex]) {
        if (!data.selectedOptions[data.tabIndex].hasChildren) {
          this.hasChildren = false;
        } else {
          this.hasChildren = true;
        }
      }
    },
    focusEvent(e) {
      let ele = e.target;
      let loginButton = document.getElementById("placeholder-btn");
      loginButton.style = "height: 100px";
      ele.scrollIntoView(true);
    },
    blurEvent() {
      let kdv = document.getElementById("placeholder-btn");
      kdv.style = "height:0px";
    },
    //发送验证码
    sendCodes() {
      if (!this.aoiUserInfoForm.mobileNum) {
        this.$toast("请先输入手机号码");
        return;
      }
      if (!/^(1)\d{10}$/.test(this.aoiUserInfoForm.mobileNum)) {
        this.$toast({
          message: "手机号码格式不正确，请重新输入",
          duration: 3000,
        });
        return;
      }
      this.messageCode = false;
      this.startTimer();
      this.sendAuthCode();
    },
    sendAuthCode() {
      this.$axios
        .post(sendCode, null, {
          params: {
            mobileNum: this.aoiUserInfoForm.mobileNum,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            console.log("res----->" + res.data);
            this.$toast(res.msg);
          }
        });
    },

    //开始倒计时
    startTimer() {
      const interval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.messageCode = true;
          clearInterval(interval);
        }
      }, 1000);
    },
    //确认
    confirmSure() {
      const result = this.verifyFields();
      if (result) {
        if (this.isRegister == 0) {
          this.aoiRegister();
        } else {
          delete this.aoiUserInfoForm.spaceId;
          this.aoiLogin();
        }
      }
    },
    //注册
    aoiRegister() {
      this.$axios.post(aoiRegister, this.aoiUserInfoForm).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          storage.set("systemUserInfo", data);
          this.$store.commit("setUserId", data.userId);
          this.$store.commit("setHouseId", data.houseId);
          this.$store.commit("setTenantId", data.tenantId);
          this.$store.commit("setCommunityId", data.communityId);
          this.$router.push({
            name: "guidePage",
          });
        }
      });
    },
    //登录
    aoiLogin() {
      this.$axios.post(aoiLogin, this.aoiUserInfoForm).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          storage.set("systemUserInfo", data);
          this.$store.commit("setUserId", data.userId);
          this.$store.commit("setHouseId", data.houseId);
          this.$store.commit("setTenantId", data.tenantId);
          this.$store.commit("setCommunityId", data.communityId);
          this.$router.push({
            name: "guidePage",
          });
        }
      });
    },
    //校验字段
    verifyFields() {
      let result = true;
      if (!this.aoiUserInfoForm.mobileNum) {
        this.$toast("请先输入手机号码");
        return;
      }
      if (!/^(1)\d{10}$/.test(this.aoiUserInfoForm.mobileNum)) {
        this.$toast({
          message: "手机号码格式不正确，请重新输入",
          duration: 3000,
        });
        result = false;
        return;
      }
      if (!this.aoiUserInfoForm.verificationCode) {
        this.$toast({ message: "请输入验证码", duration: 3000 });
        result = false;
        return;
      }
      if (this.isRegister == 0 && !this.aoiUserInfoForm.spaceId) {
        this.$toast({ message: "请选择房号", duration: 5000 });
        result = false;
        return;
      }
      if (this.isRegister == 0 && this.hasChildren) {
        this.$toast({ message: "请选择房号", duration: 5000 });
        result = false;
        return;
      }
      return result;
    },
    //勾选完
    onFinish({ selectedOptions }) {
      this.isShowCas = false;
      this.houseName = selectedOptions.map((option) => option.name).join("-");
    },
    //获取房号
    getRoomList() {
      this.$axios
        .get(`${areaLinkUrl}?specifyCollectionTypes=1&topSpaceId=P1C2A2S1D1`)
        .then((res) => {
          if (res.code == 200) {
            this.areaItemList = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.focusState {
  position: absolute;
}
.phone {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 56px 177px 57px 256px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .submit-t {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .phone-number,
    .code,
    .roomNumber {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      .value {
        flex: 1;
        padding: 0 15px;
        .room {
          padding: 0 15px;
          width: 100%;
          height: 50px;
          box-sizing: border-box;
          border-radius: 6px;
          border: 1px solid #e4e7ed;
          box-sizing: border-box;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 16px;
          line-height: 50px;
          color: #2f3a4f;
          text-align: left;
          padding: 0 15px;
        }
      }
      input {
        flex: 1;
        height: 50px;
        border-radius: 6px;
        border: 1px solid #e4e7ed;
        font-weight: 400;
        font-size: 16px;
        color: #000;
        font-style: normal;
        padding: 0 15px;
        margin: 0 15px;
        box-sizing: border-box;
        &::placeholder {
          font-weight: 400;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.5);
          font-style: normal;
        }
      }
      .label {
        width: 50px;
        white-space: nowrap;
        font-weight: 400;
        font-size: 16px;
        color: #2f3a4f;
        line-height: 22px;
        font-style: normal;
      }
      .btn {
        width: 150px;
        height: 50px;
        background: #1874fd;
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;
        color: #fff;
        line-height: 50px;
        text-align: center;
        font-style: normal;
        &.placeholder {
          background-color: #fff;
        }
      }
    }
    .btn {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  .submit-b {
    width: 239px;
    height: 50px;
    box-sizing: border-box;
    background: #1874fd;
    border-radius: 6px;
    // padding: 13px 102px;
    font-weight: 600;
    font-size: 18px;
    color: #ffffff;
    line-height: 50px;
    text-align: center;
    font-style: normal;
  }
}
</style>
